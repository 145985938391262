.card-img-left{
    .card-body{
        padding: 0 0 0 1rem;
    }
}
.card-img-right{
    .card-body{
        padding: 0 1rem 0 0;
    }
}
.card-container{
	.card{	
    	border-color: transparent;
		background-color: transparent;
	}
    .row{
      display: flex!important;
    }
    div[class^="col"]{
      align-self: center !important;
  
    }
}
.jumbotron-btn{
    width: 100%;
}
.jumbotron-lead{
    margin: 0;
}