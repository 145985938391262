.bg-image{
    background-image: url('https://calirextn.com/assets/images/loading-background.svg');
    height:27vw;
    background-size: cover;
    background-position: center;
}
.cover-text-bloc{
    margin-left: -2rem;
}
@media screen and (max-width:767px){
    .bg-image{
        height: 45vw;
        background-position: right;
    }   
}
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-1000%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .cover-icon {  
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 3s ease-out 0s 1 slideInFromLeft;
    width:12rem;
    position: absolute;
    z-index: 9;
    right: 2rem;
    top:27vw;
  }
  .cover-image{
    width: 45%;
    right: 0rem;
    margin-bottom: 2.3%;
    margin-left: 10rem;
  }