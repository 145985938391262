.dropdown-item {
    color: #0943ea ;
}
.dropdown-menu{
    border: none;
}
.navbar-dark .navbar-nav .nav-link.active {
    color: #0943ea!important;
}
.navbar-dark .navbar-nav .nav-link {
    color: white;
}
.navbar .container{
    z-index:1;
}
.page-header{
    height: 3rem;
}
@media screen and (max-width:1023px) {
    .page-header{
        height: unset;
    } 
}