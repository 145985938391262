.home_page_slider {
	height: 400px;
    .carousel-inner{
        height: 100%;

        .carousel-item::before { 
            opacity:0.5;
        }
        .carousel-item.contain{
            background-size:contain!important;
        }
        .carousel-item.cover{
            background-size:cover!important;
        }
        .carousel-item{
            background-color : #eab009!important;
            background-repeat:no-repeat!important;
            background-position:center bottom!important;
        }
    }

}
@media screen and (max-width:767px) {
    .home_page_slider {
        height: 250px;
        .carousel-inner{
            height: 100%;
            .carousel-item{
                background-position:center top!important;
            }
        }
    }   
}