$theme-colors: (
  "primary": #0943ea,
  "secondary": #eab009,
  "white": #ffffff,
  "tertiary": #ea6b09,
  "quaternary": #7609ea,
  "warning": #ea6b09,
  "danger": #ea0950,
  "success": #50ea09
);
@font-face {
  font-family: "Dolce Vita";
  src: url("https://calirextn.com/assets/dolce-vita-font/dolce_vita-webfont.eot");
  src:
  url("https://calirextn.com/assets/dolce_vita-webfont.woff") format("woff"),
  url("https://calirextn.com/assets/dolce_vita-webfont.woff2") format("woff2"),
  url("https://calirextn.com/assets/dolce_vita-webfont.ttf") format("ttf"),
  url("https://calirextn.com/assets/dolce_vita-webfont.svg") format("svg");
  }

.mea-text{
  font-family: 'Dolce Vita', Helvetica, Arial, sans-serif!important;
}
body{
  background-color:#ffffff!important;
  color: #021650!important;

}
@media screen and (max-width:767px){
  .has-text-left-mobile{
    text-align: left!important;
  }
}
@media screen and (max-width:1023px){
  .has-text-left-touch{
    text-align: left!important;
  }
}
@media screen and (max-width:767px){
  .has-text-right-mobile{
    text-align: right!important;
  }
}
@media screen and (max-width:1023px){
  .has-text-right-touch{
    text-align: right!important;
  }
}

@media screen and (max-width:767px){
  .has-text-centered-mobile{
    text-align: center!important;
  }
}
@media screen and (max-width:1023px){
  .has-text-centered-touch{
    text-align: center!important;
  }
}
.is-white{
  color: #ffffff;
}
.is-marginless{
  margin:0;
}
.is-size-45{
  font-size: 4.5rem!important;
}
.is-size-38{
  font-size: 3.8rem!important;
}
.is-size-30{
  font-size: 3rem!important;
}
.is-size-24{
  font-size: 2.4rem!important;
}
.is-size-20{
  font-size: 2rem!important;
}
.is-size-18{
  font-size: 1.8rem!important;
}
.is-size-15{
  font-size: 1.5rem!important;
}
.is-size-13{
  font-size: 1.3rem!important;
}
.is-size-11{
  font-size: 1.1rem!important;
}
.is-size-9{
  font-size: 0.9rem!important;
}
@media screen and (max-width: 1023px){
  .is-hidden-touch{
    display: none;
  } 
}
@media screen and (min-width: 1024px){
  .is-hidden-desktop{
    display: none;
  } 
}
@media screen and (max-width: 1023px) {
  .is-hidden-mobile{
    display: none;
  }
  .is-size-45{
    font-size: 3.8rem!important;
  }
  .is-size-38{
    font-size: 3rem!important;
  }
  .is-size-30{
    font-size: 2.4rem!important;
  }
  .is-size-28{
    font-size: 2.2rem!important;
  }
  .is-size-24{
    font-size: 2rem!important;
  }
  .is-size-20{
    font-size: 1.6rem!important;
  }
  .is-size-18{
    font-size: 1.5rem!important;
  }
  .is-size-15{
    font-size: 1.3rem!important;
  }
  .is-size-13{
    font-size: 1.1rem!important;
  }
  .is-size-11{
    font-size: 0.9rem!important;
  }
  .is-size-9{
    font-size: 0.9rem!important;
  }
}


@media screen and (max-width: 767px) {
  .is-hidden-mobile{
    display: none;
  }
  .is-size-45{
    font-size: 3.8rem!important;
  }
  .is-size-38{
    font-size: 3rem!important;
  }
  .is-size-30{
    font-size: 2.4rem!important;
  }
  .is-size-28{
    font-size: 1.6rem!important;
  }
  .is-size-24{
    font-size: 2rem!important;
  }
  .is-size-20{
    font-size: 1.6rem!important;
  }
  .is-size-18{
    font-size: 1.5rem!important;
  }
  .is-size-15{
    font-size: 1.3rem!important;
  }
  .is-size-13{
    font-size: 1.1rem!important;
  }
  .is-size-11{
    font-size: 0.9rem!important;
  }
  .is-size-9{
    font-size: 0.9rem!important;
  }
  .is-size-38-mobile{
    font-size: 3.8rem!important;
  }
  .is-size-30-mobile{
    font-size: 3rem!important;
  }
  .is-size-24-mobile{
    font-size: 2.4rem!important;
  }
  .is-size-20-mobile{
    font-size: 2rem!important;
  }
  .is-size-18-mobile{
    font-size: 1.8rem!important;
  }
  .is-size-15-mobile{
    font-size: 1.5rem!important;
  }
  .is-size-13-mobile{
    font-size: 1.3rem!important;
  }
  .is-size-11-mobile{
    font-size: 1.1rem!important;
  }
  .is-size-9-mobile{
    font-size: 0.9rem!important;
  }
  .is-size-7-mobile{
    font-size: 0.7rem!important;
  }
}
.is-borderless{
  border:none!important;
}
.has-background-secondary{
  background-color: #eab009!important;
}
.has-background-white{
  background-color: #ffffff!important;
}
.is-fullwidth{
  width: 100%;
}
.is-fullheight{
  height: 100%;
}
.is-bold{
  font-weight: 600;
}
.standalone-img{
  box-shadow: 0 0.2rem 0.4rem 0 rgb(0 0 0 / 10%);
  padding: 0!important;
  width: 100%;

  
}
.is-hidden{
  display: none!important;
}
.has-text-centered{
  text-align:center;
}


.auth-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  max-width: 100%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 30px;
  border-radius: 10px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.form-group {
  margin-bottom: 1rem;
}
.pageloader{
  background-image: url('https://calirextn.com/assets/images/loading-background.svg');
  background-size: cover;
  min-height: 100vh;

}
.pageloader-content {
  text-align: center;
  top:30%;    
  top: 50%;
  position:absolute;
  width: 100%;

}
.bootstrap-data-table{
  width: 85%;
}
h1, h2, h3, h4, h5, h6, label, span {
  font-weight: 600;
  font-family: 'Fira Sans', sans-serif;
}
input, button, select, optgroup, textarea {
   margin: revert!important;
}

.popout-card:hover .btn-outline-primary{
  color:white;
  background:#0943ea
}
.popout-card {
  border: unset!important;
}
.popout-card:hover {


  transform: scale(1.02, 1.02);
  -webkit-transform: scale(1.02, 1.02);
  backface-visibility: hidden; 
  will-change: transform;
  box-shadow: 0 1rem 3rem rgba(0,0,0,.75) !important;
}

@media screen and (min-width:768px){
  .breadcrumb-item.is-white a,.breadcrumb-item.active.is-white,.breadcrumb-item.is-white + .breadcrumb-item.is-white::before{
    color: #ffffff!important
  } 
  .breadcrumb-item:not(.is-white) a,.breadcrumb-item.active:not(.is-white){
    color: #0943ea!important
  }
}
@media screen and (max-width:767px){
  .breadcrumb-item.is-white a,.breadcrumb-item.active.is-white,.breadcrumb-item.is-white + .breadcrumb-item.is-white::before{
    color: #0943ea!important
  } 
  .breadcrumb-item:not(.is-white) a,.breadcrumb-item.active:not(.is-white){
    color: #ffffff!important
  }
}
.info-table{
  overflow: scroll;
  position: relative;
}
.info-table::-webkit-scrollbar {
  display: none;
}
.dropdown-menu.show{
  background-color: #eab009;
}
.react-bootstrap-table .table.table-bordered{
  background: white;
  border-radius: 8px;
  box-shadow: 0 1rem 2rem rgb(0 0 0 / 29%) !important;

}
.react-bootstrap-table th.sortable {
	cursor: pointer
}

.react-bootstrap-table th .order>.dropdown>.caret {
	margin: 10px 0 10px 5px;
	color: #ccc
}

.react-bootstrap-table th .order>.dropup>.caret {
	margin: 10px 0;
	color: #ccc
}

.react-bootstrap-table th>.react-bootstrap-table-sort-order>.caret {
	margin: 10px 6.5px
}

.react-bootstrap-table th .order-4:before {
	margin-left: 3.5px;
	content: "\2191";
	opacity: .4
}

.react-bootstrap-table th .order-4:after {
	content: "\2193";
	opacity: .4
}

.react-bootstrap-table th .caret-4-asc:before {
	margin-left: 3.5px;
	content: "\2191"
}

.react-bootstrap-table th .caret-4-asc:after {
	content: "\2193";
	opacity: .4
}

.react-bootstrap-table th .caret-4-desc:before {
	margin-left: 3.5px;
	content: "\2191";
	opacity: .4
}

.react-bootstrap-table th .caret-4-desc:after {
	content: "\2193"
}

.toggle-button{
  margin-right: 0.5rem!important;
}
@media all {
  .page-break {
    display: block;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: flex;
    page-break-before: auto;
  }
}
.btn.btn-info.btn-add.col-xs-12{
  color: white;
  width: 92px;
  background-color: var(--bs-primary);
  height: 37px;
  font-weight: 600;
}
.btn.btn-info.btn-add.col-xs-12:after {
  content: '+Ajouter';
}
@page {
  size: 50%;
  margin: 20mm;
}

.btn.btn-danger.array-item-remove{
  color: white;
  max-width: 123px;
  height: 37px;
  font-weight: 600;
}
.btn.btn-danger.array-item-remove:after {
  content: 'Supprimer';
}
@import '~bootstrap/scss/bootstrap.scss';

