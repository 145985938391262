.pricing-card {
    border:none;
    padding: 10px 50px;
  }

  .pricing-card::after {
    position: absolute;
    z-index: -1;
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .pricing-card:hover {


    transform: scale(1.02, 1.02);
    -webkit-transform: scale(1.02, 1.02);
    backface-visibility: hidden; 
    will-change: transform;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.75) !important;
  }

  .pricing-card:hover::after {
    opacity: 1;
  }

  .pricing-card:hover .btn-outline-primary{
    color:white;
    background:#0943ea
  }
  .pricing-card .card-footer {
    background:transparent;
  }
  .pricing-card .badge{
    position: absolute;
    right: 1rem;
  }
  .pricing-card .price-card-title{
    margin: auto;
    width:fit-content;
  }

  .pricing-card{
    padding: 10px 15px!important;
  }
  .btn-card:hover{
    background-color: white;
    color:#0943ea;
  }
  .badge-card{
    border : solid 1px;
    border-radius: 0 0.5rem 0 0;
  }
  .price-card-footer{
    border-top : 0;
  }