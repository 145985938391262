.video-responsive {
	overflow: hidden;
	position: relative;
	text-align: center;
	padding: 2rem;
	iframe {
		left: 0;
		top: 0;
		width : 678.75px;
		height : 381.875px;
	}
}

@media screen and (max-width : 1023px) {
	.video-responsive {
		iframe {
			width: 100%;
			height: 48vw;
		}
	}
}
